.dishop-iso .dark-theme {
  background-color: black;
}

.dishop-iso .dark-theme .productCard {
  background-color: #0c0c0c;
  color: white;
}

.dishop-iso .dark-theme .productCard:hover {
  border: 0.1mm solid white;
}

.dishop-iso .dark-theme .text-description {
  color: #d5d5d5;
}

.dishop-iso .dark-theme .text-color {
  color: white !important;
}

.dishop-iso .dark-theme .text-color-secondary {
  color: white !important;
}

.dishop-iso .dark-theme #sticky-footer {
  color: white;
}

.dishop-iso .dark-theme #sticky-footer a {
  color: white;
}

.dishop-iso .dark-theme #sticky-cart {
  background-color: #0c0c0c;
}

.dishop-iso .dark-theme .custom-border-bottom {
  border-bottom: solid;
  border-bottom-width: 2px;
  border-color: white;
}

.dishop-iso .dark-theme .custom-border-bottom-none {
  border-bottom: solid;
  border-bottom-width: 2px;
  border-color: transparent;
}

.dishop-iso .dark-theme .info-bar {
  color: white;
  background-color: black;
}

.dishop-iso .dark-theme .section-bar {
  color: white;
  background-color: #3f3f3f;
}

.dishop-iso .dark-theme .border-color {
  border: 1px solid #fff!important;
}
.dishop-iso .dark-theme .dark-modal {
  background: #202022!important;
  color:#fff!important;
}
.dishop-iso .dark-theme .footer-color {
  background-color:  #202020;
  color:  #fff;
  position: sticky;
  bottom: 0;
  z-index: 1055
}
.dishop-iso .dark-theme .close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-shadow: 0 1px 0 #fff;
}
.dishop-iso .dark-theme .close:hover {
  color: #fff!important;
  text-decoration: none;
}
.dishop-iso .dark-theme .label {
  color: #fff!important;
}
.dishop-iso .dark-theme .disabled-label {
  color: #6B757E!important;
}
.dishop-iso .dark-theme .hr {
  background-color: #fff!important;
}
.dishop-iso .dark-theme .border-radius {
border-radius: 16px!important;
}
.dishop-iso .dark-theme .dark-border {
  background-color: rgb(63, 63, 63)!important
}
.dishop-iso .dark-theme .dark-border-bottom {
  border-bottom: solid;
  border-bottom-width: 2px;
  border-color: white;
}
.dishop-iso .dark-theme input:-webkit-autofill  {
  -webkit-box-shadow: 0 0 0 30px  #202022 inset !important;
  -webkit-text-fill-color: white !important;
  transition: background-color 5000s ease-in-out 0s;
}
.dishop-iso .dark-theme .MuiSelect-icon {
  color: white!important;
  right: 13px!important;
}
.dishop-iso .dark-theme .menu > li:hover {
  background-color: rgb(42, 136, 139);
  color:white!important
}
.dishop-iso .dark-theme .dark-color {
  color:white!important;
  background-color: #434242!important
}
.dishop-iso .dark-theme .fixed-cart-bottom {
  border-radius: 16px!important;
  color:white!important;
  position: fixed!important;
  right: 20px;
  bottom: 16px;
  left: 20px;
  z-index: 1030;
  width: -moz-available;
}